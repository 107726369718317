.day {
  aspect-ratio: 1;
  border-radius: 12px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.462);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.52);
  font-size: 50px;
  cursor: pointer;
}

.day.current {
  color: white;
  transition: transform 0.3s ease;
}

.day.current:hover {
  transform: scale(1.1);
}

.day.selected {
  background-color: #9B1B30;
}

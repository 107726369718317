.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.content {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.link {
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: color 0.2s ease;
}

.sponsor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sponsor:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}

.sponsorIcon {
  transition: transform 0.2s ease;
}

.sponsor:hover .sponsorIcon {
  transform: scale(1.1);
}

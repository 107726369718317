.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
  background-image: url("./background.jpg");
  background-position: center;
  background-size: cover;
  font-family: "Niconne", cursive;
  padding-bottom: 60px; /* Add space for footer */
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 40px;
  padding: 20px;
  width: 90%;
}

.wrapper {
  position: fixed;
  bottom: 35px;
  right: 20px;
  top: 35px;
  width: 320px;
  z-index: 10;
}

.inspector {
  position: relative;
  height: 92%;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 20px;
}

@media (min-width: 768px) {
  .inspector {
    padding-right: 0;
  }
}

.inspector.active {
  display: flex;
}

.content {
  overflow-y: auto;
  flex-grow: 1;
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-family: 'Crimson Text', serif;
}

.content h2 {
  font-size: 24px;
  margin: 0 0 15px 0;
  font-family: 'Niconne', cursive;
  color: #222;
}

.content h3 {
  font-size: 20px;
  margin: 20px 0 10px;
  color: #444;
  font-family: 'Niconne', cursive;
}

.content p {
  margin-bottom: 10px;
  line-height: 1.6;
  font-size: 17px;
}

.content code {
  background-color: #f1f1f1;
  padding: 4px 6px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
}

.codeBlock {
  background-color: #2d2d2d !important;
  border-radius: 8px;
  padding: 15px !important;
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: auto;
}

.codeBlock code {
  font-family: 'Fira Code', 'Consolas', monospace;
  background: none !important;
  padding: 0 !important;
  border-radius: 0;
  font-size: 14px;
  color: #fff;
}

.browserSupport ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.browserSupport li {
  padding: 5px 0;
  font-family: 'Crimson Text', serif;
  font-size: 17px;
}

.links ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.links li {
  margin: 8px 0;
  font-size: 17px;
}

.links a {
  color: #9B1B30;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-family: 'Crimson Text', serif;
}

.links a:hover {
  text-decoration: underline;
}

.iconLink {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #9B1B30;
  text-decoration: none;
}

.iconLink:hover {
  text-decoration: underline;
}

.iconLink svg {
  flex-shrink: 0;
}

.youtubeLink {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #9B1B30;
  text-decoration: none;
}

.youtubeLink:hover {
  text-decoration: underline;
}

.youtubeLink svg {
  flex-shrink: 0;
}

.closeButton {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  cursor: pointer;
  font-size: 24px;
  color: #9B1B30;
  font-weight: 300;
  line-height: 1;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  font-family: 'Crimson Text', serif;
  transform: rotate(45deg);
  z-index: 2;
}

.closeButton:hover {
  background-color: #9B1B30;
  color: white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transform: rotate(135deg);
}

@media (max-width: 768px) {
  .wrapper {
    right: 12px;
    left: 12px;
    bottom: 12px;
    top: 12px;
    width: auto;
  }

  .inspector {
    border-radius: 12px;
    height: 100%;
    margin: 0;
    max-height: calc(100vh - 24px);
  }

  .inspector.active {
    display: flex;
  }

  .content {
    padding-right: 10px;
    margin-right: -10px;
  }
}
